<template> 
  <base-view-layout v-if="activity.id">
  <template #breadcrumb v-if="!isTutor"> 
     <li class="breadcrumb__nav-item breadcrumb__nav-item--back">
        <router-link :to="{ name: 'Activities' }">
         <img src="@/assets/images/icons/ico-chev-left--70L.svg">
        </router-link>
      </li>
      <li class="breadcrumb__nav-item">
        <router-link :to="{ name: 'Activities' }">{{$t('activityshow.actividades')}}</router-link>
      </li>
      <li class="breadcrumb__nav-item">{{activity.title}}</li>
    </template>

  <template #title>{{activity.title}}</template>
  
    <!-- <template #subtitle>¡Hola! Gracias por participar, esperamos que lo pasen bien y que la actividad les ayude a entender más de matemáticas.</template>     -->
  <div v-if="activity.instruction.includes('slide divider')" class="with-carrousel">
    <div class="main-activity-with-carrousel">
      <div class="content-with-carrousel">
        <div class="side-info-with-carrousel"  :class="{'no-side-info':!showMaterial}">
          <div v-if="!isTutor" class="about-activity">
            <h3 class="main-section__title">{{$t('activityshow.aboutacti')}}</h3>
            <div class="box box--sm box--no-shadow">
              
              <div v-if="$i18n.locale == 'es'" class="activity-information">
                <div v-if="isCurrentlyOrIndefinitelyActive && deactivateOptions" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.enlace')}}</p>
                  <p class="activity-information__description"> <img class="resource__icon_resize" src="@/assets/images/icons/copy.svg" @click="copy('enlace5')"> {{activityLink.slice(0,38)}}...</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
                  <p class="activity-information__description">{{activity.grade.name}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
                  <p class="activity-information__description">{{activity.subject.name}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
                  <p class="activity-information__description">{{activity.curricular_axis.name}}</p>
                </div>
                <div v-if="isActivatable && activity.activity_event.end_date && deactivateOptions"  class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                  <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
                  <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
                  <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
                  <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
                </div>
                <div v-else-if="isActivatable && deactivateOptions" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                  <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>
                </div>
                <div v-if="activity.learning_objectives" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
                  <p class="activity-information__description" v-html="activity.learning_objectives"></p>
                </div>
                <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
                  <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.por')}}</p>
                  <p class="activity-information__description">{{activityAuthor}}</p>
                </div>
              </div>

              <div v-else-if="$i18n.locale == 'en'" class="activity-information">
                <div v-if="isCurrentlyOrIndefinitelyActive && deactivateOptions" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.enlace')}}</p>
                  <p class="activity-information__description"> <img class="resource__icon_resize" src="@/assets/images/icons/copy.svg" @click="copy('enlace5')"> {{activityLink.slice(0,38)}}...</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
                  <p class="activity-information__description">{{activity.grade.name_en}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
                  <p class="activity-information__description">{{activity.subject.name_en}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
                  <p class="activity-information__description">{{activity.curricular_axis.name_en}}</p>
                </div>
                <div v-if="isActivatable && activity.activity_event.end_date && deactivateOptions"  class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                  <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
                  <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
                  <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
                  <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
                </div>
                <div v-else-if="isActivatable && deactivateOptions" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                  <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>
                </div>
                <div v-if="activity.learning_objectives" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
                  <p class="activity-information__description" v-html="activity.learning_objectives"></p>
                </div>     
                <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
                  <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
                </div>
                <div class="activity-information__item">
                  <p class="activity-information__title">{{$t('activityshow.por')}}</p>
                  <p class="activity-information__description">{{activityAuthor}}</p>
                </div>
              </div>

              <div v-if="isEditable || isDeletable || isActivatable" class="form__actions">
                <div class="dropdown">
                  <button class="btn btn--primary btn--block">{{$t('activityshow.btn')}}</button>
                  <div class="dropdown__options">
                    <a v-if="isCurrentlyOrIndefinitelyActive && isActivatable && deactivateOptions" href="" @click.prevent="displayDeactivationModal()">{{$t('activityshow.deactactivi')}}</a>
                    <a v-if="isCurrentlyOrIndefinitelyActive && isActivatable && deactivateOptions" href="" @click.prevent="displayActivationModal(), typeActivation()">{{$t('activityshow.editarActivacion')}}</a>
                    <router-link v-if="isEditable" :to="{ name: 'ActivitiesEdit', params: { activityId: activity.id } }">{{$t('activityshow.editactivi')}}</router-link>
                    <a v-if="isDuplicable" href="/activities" @click.prevent="duplicateActivity()">{{$t('activityshow.duplicarActividad')}}</a>
                    <a href="" class="danger" v-if="isDeletable" @click.prevent="showConfirmDeletionModal = true">{{$t('activityshow.deltactivi')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="external-resourses">
            <div v-if="activity.external_resource_url_print && !isTutor" class="main-section">
              <h3 class="main-section__title">{{$t('activityshow.recursosPrint')}}</h3>
              <div class="box box--sm box--no-shadow">
                <div class="resource">
                  <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace1')">
                  <a :href="activity.external_resource_url_print" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
                </div>
              </div>
            </div>
            <div v-if="activity.external_resource_url_recom && !isTutor" class="main-section">
              <h3 class="main-section__title">{{$t('activityshow.recursosRecom')}}</h3>
              <div class="box box--sm box--no-shadow">
                <div class="resource">
                  <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace2')">
                  <a class="link-style" @click="copy('enlace2')">{{$t('activityshow.verPdf')}}</a>
                </div>
              </div>
            </div>
            <div v-if="activity.guia_docente_interactiva && !isTutor" class="main-section">
              <h3 class="main-section__title">{{$t('activityshow.docenteinteractiva')}}</h3>
              <div class="box box--sm box--no-shadow" v-html="this.activity.guia_docente_interactiva"></div>
              <!--<div class="box box--sm box--no-shadow">
                <div class="resource">
                  <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace4')">
                  <a :href="activity.guia_docente_interactiva" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
                </div>
              </div>-->
            </div>
            <div v-if="activity.external_resource_url_apod && isTutor" class="main-section">
              <h3 class="main-section__title">{{$t('activityshow.enlaceApod')}}</h3>
              <div class="box box--sm box--no-shadow">
                <div class="resource">
                  <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace3')">
                  <a :href="activity.external_resource_url_apod" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
                </div>
              </div>
            </div>
            <div v-else-if ="activity.external_resource_url_apod && !isTutor" class="main-section">
              <h3 class="main-section__title">{{$t('activityshow.enlaceApod')}}</h3>
              <div class="box box--sm box--no-shadow">
                <div class="resource">
                  <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace3')">
                  <a :href="activity.external_resource_url_apod" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="carrousel">
          <div v-if="expandedTutorSideNav " class="slide">
            <Carousel :items-to-show="1" >
                <Slide v-for="(instruction, key) in aux2" :key="key" >
                  <div class="slide-content" v-html="instruction.name"></div>
                </Slide>
                <template #addons>
                  <Navigation />
                  <Pagination />
                </template>
            </Carousel>
          </div>
          <div v-else-if="!expandedTutorSideNav " class="slide">
            <Carousel :items-to-show="1" >
                <Slide v-for="(instruction, key) in aux2" :key="key" >
                  <div class="slide-content" v-html="instruction.name"></div>
                </Slide>

                <template #addons>
                  <Navigation />
                  <Pagination />
                </template>
            </Carousel>
          </div>

        

         
        </section>
        <section class="content-with-carrousel-questions">
          <h3 class="main-section__title" v-if="activity.questions.length">{{$t('activityshow.preguntas')}}</h3>
          <form @submit.prevent="saveAnswers()" class="activity-questions">
            <display-question v-for="(question, index) in activity.questions" :key="question.id" :question="question" v-model="answers[index].value"/>

            <!-- Solucion momentanea más triste del mundo :( -->
            <br><br><br><br>
          </form>
        </section>
      </div>
    </div>
  </div>
  <div v-else class="without-carrousel">
    <div class="grid activity-grid">
      <div class="main-section-side activity-side--content">
        <section class="main-section"  >
         <div class="box box--sm box--no-shadow" v-html="activity.instruction"></div>
        </section>
        <section class="main-section">
          <h3 class="main-section__title" v-if="activity.questions.length">{{$t('activityshow.preguntas')}}</h3>
          <form @submit.prevent="saveAnswers()" class="activity-questions">
            <display-question v-for="(question, index) in activity.questions" :key="question.id" :question="question" v-model="answers[index].value"/>

            <!-- Solucion momentanea más triste del mundo :( -->
            <br><br><br><br>

            <div v-if="isTutor" class="form__actions form__actions--fixed">
              <div class="form__actions-side">
              </div>
              <div class="form__actions-side">
                <button class="btn btn--primary" type="submit" :disabled="submitting">
                  <template v-if="activity.questions.length">{{$t('activityshow.sendresp')}}</template>
                  <template v-else>{{$t('activityshow.finishacti')}}</template>
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <div class="main-section__side activity-side--info">
        <div v-if="!isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.aboutacti')}}</h3>
          <div class="box box--sm box--no-shadow">
            <div v-if="$i18n.locale == 'es'" class="activity-information">
              <div v-if="isCurrentlyOrIndefinitelyActive  && deactivateOptions" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.enlace')}}</p>
                <p class="activity-information__description"><img class="resource__icon_resize" src="@/assets/images/icons/copy.svg" @click="copy('enlace5')"> {{activityLink.slice(0,38)}}...</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
                <p class="activity-information__description">{{activity.grade.name}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
                <p class="activity-information__description">{{activity.subject.name}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
                <p class="activity-information__description">{{activity.curricular_axis.name}}</p>
              </div>

              <div v-if="isActivatable && activity.activity_event.end_date  && deactivateOptions" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
                <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
                <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
                <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
              </div>
              <div v-else-if="isActivatable  && deactivateOptions" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>               
              </div>
              <div v-if="activity.learning_objectives" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
                <p class="activity-information__description" v-html="activity.learning_objectives"></p>
              </div>
              <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
                <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.por')}}</p>
                <p class="activity-information__description">{{activityAuthor}}</p>
              </div>
            </div>

            <div v-else-if="$i18n.locale == 'en'" class="activity-information">
              <div v-if="isCurrentlyOrIndefinitelyActive  && deactivateOptions" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.enlace')}}</p>
                <p class="activity-information__description"> <img class="resource__icon_resize" src="@/assets/images/icons/copy.svg" @click="copy('enlace5')"> {{activityLink.slice(0,38)}}...</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
                <p class="activity-information__description">{{activity.grade.name_en}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
                <p class="activity-information__description">{{activity.subject.name_en}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
                <p class="activity-information__description">{{activity.curricular_axis.name_en}}</p>
              </div>


              <div v-if="isActivatable && activity.activity_event.end_date && deactivateOptions"  class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
                <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
                <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
                <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
              </div>
              <div v-else-if="isActivatable && deactivateOptions" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
                <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>
              </div>
              <div v-if="activity.learning_objectives" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
                <p class="activity-information__description" v-html="activity.learning_objectives"></p>
              </div>
              <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
                <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
              </div>
              <div class="activity-information__item">
                <p class="activity-information__title">{{$t('activityshow.por')}}</p>
                <p class="activity-information__description">{{activityAuthor}}</p>
              </div>
            </div>

            <div v-if="isEditable || isDeletable || isActivatable" class="form__actions">
              <div class="dropdown">
                <button class="btn btn--primary btn--block">{{$t('activityshow.btn')}}</button>
                <div class="dropdown__options">
                  <a v-if="isCurrentlyOrIndefinitelyActive && isActivatable && deactivateOptions" href="" @click.prevent="displayDeactivationModal()">{{$t('activityshow.deactactivi')}}</a>
                  <a v-if="isCurrentlyOrIndefinitelyActive && isActivatable && deactivateOptions" href="" @click.prevent="displayActivationModal(), typeActivation()">{{$t('activityshow.editarActivacion')}}</a>
                  <router-link v-if="isEditable" :to="{ name: 'ActivitiesEdit', params: { activityId: activity.id } }">{{$t('activityshow.editactivi')}}</router-link>
                  <a v-if="isDuplicable" href="/Activities" @click.prevent="duplicateActivity()" >{{$t('activityshow.duplicarActividad')}}</a>
                  <a href="" class="danger" v-if="isDeletable" @click.prevent="showConfirmDeletionModal = true">{{$t('activityshow.deltactivi')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activity.external_resource_url_print && !isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.recursosPrint')}}</h3>
          <div class="box box--sm box--no-shadow">
            <div class="resource">
              <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace1')">
              <a :href="activity.external_resource_url_print" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
            </div>
          </div>
        </div>
        <div v-if="activity.external_resource_url_recom && !isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.recursosRecom')}}</h3>
          <div class="box box--sm box--no-shadow">
            <div class="resource">
              <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace2')">
              <a class="link-style" @click="copy('enlace2')">{{$t('activityshow.verPdf')}}</a> 
            </div>
          </div>
        </div>
        <div v-if="activity.guia_docente_interactiva && !isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.docenteinteractiva')}}</h3>
          <div class="box box--sm box--no-shadow" v-html="this.activity.guia_docente_interactiva"></div>
          <!--<div class="box box--sm box--no-shadow">
            <div class="resource">
              <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace4')">
              <a :href="activity.guia_docente_interactiva" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
            </div>
          </div>-->
        </div>
        <div v-if="activity.external_resource_url_apod && isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.enlaceApod')}}</h3>
          <div class="box box--sm box--no-shadow">
            <div class="resource">
              <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace3')">
              <a :href="activity.external_resource_url_apod" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
            </div>
          </div>
        </div>
        <div v-else-if ="activity.external_resource_url_apod && !isTutor" class="main-section">
          <h3 class="main-section__title">{{$t('activityshow.enlaceApod')}}</h3>
          <div class="box box--sm box--no-shadow">
            <div class="resource">
              <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace3')">
              <a :href="activity.external_resource_url_apod" target="_blank">{{$t('activityshow.hazclickaqui')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </base-view-layout>
  

  <modal v-if="showConfirmDeletionModal" @close="showConfirmDeletionModal = false">
    <template #title>
      {{$t('activityshow.deltactivi')}}
    </template>
    <template #description>
      {{$t('activityshow.deletedesc')}}
    </template>
    <template #content>
      <p>{{$t('activityshow.questiondel')}}</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn--tertiary danger" @click.prevent="deleteActivity()" :disabled="submitting">{{$t('activityshow.confirmdelete')}}</button>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showConfirmDeletionModal = false" :disabled="submitting">{{$t('activityshow.canceldelete')}}</button>
    </template>
  </modal>

  <modal v-if="showEndingModal" @close="showEndingModal = false">
    <template #title>
      {{$t('activityshow.respenviadas')}}
    </template>
    <template #description>
      {{$t('activityshow.respdesc')}}
    </template>
    <template #content>
      <p>{{$t('activityshow.respconfirmsend')}}</p>
    </template>
    <template #footer>
      <router-link :to="{ name: 'AccessActivity' }" class="btn btn--primary btn--outline">{{$t('activityshow.salir')}}</router-link>
      <button type="button" class="btn btn--primary" @click.prevent="showEndingModal = false">{{$t('activityshow.continueactiv')}}</button>
    </template>
  </modal>

  <modal v-if="showDeactivationModal" @close="cancelDeactivation()">
    <template #title>{{$t('activityshow.deactivateactiv')}}</template>
    <template #description>{{$t('activityshow.deactivateactivdesc')}}</template>
    <template #content>
      <p>{{$t('activityshow.deactivateactivdescquest')}}</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn--tertiary danger" @click.prevent="deactivateEvent()" :disabled="submitting">{{$t('activityshow.deactivateconfirm')}}</button>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="cancelDeactivation()" :disabled="submitting">{{$t('activityshow.canceldelete')}}</button>
    </template>
  </modal>

  <modal v-if="showActivationModal" @close="showActivationModal = false">
    <template #title>{{$t('activityshow.editarActivacion')}}</template>
    <template #description>{{$t('activityshow.editarActivacionDescription')}}</template>
    <template #content>
      <form id="activationform" @submit.prevent="editActivity()">
        <div class="container-radiogroup">
          <p class="enabletime">{{$t('activitycard.timehabili')}}</p>
          <div class="radiogroup">
            <div :class="{'indefinite-inactive':disabled, 'indefinite-active': !disabled}">
              <form-input v-if="$i18n.locale == 'es' " type="radioGroup" :groupOptions="activationKindsIndefinite" v-model="activation.kind">
              </form-input>
              <form-input v-else-if="$i18n.locale == 'en' " type="radioGroup" :groupOptions="activationKindsIndefiniteEn" v-model="activation.kind">
              </form-input>
            </div>
            <div :class="{'range-indefinite':disabled, 'range': !disabled}">
              <form-input v-if="$i18n.locale == 'es' " type="radioGroup" :groupOptions="activationKindsRange" v-model="activation.kind">
              </form-input>
              <form-input v-else-if="$i18n.locale == 'en' " type="radioGroup" :groupOptions="activationKindsRangeEn" v-model="activation.kind">
              </form-input>
            </div>
          </div>
        </div>
<!---      <form-input v-if="$i18n.locale == 'es'" type="radioGroup" :groupOptions="activationKinds" v-model="activation.kind">
          {{$t('activitycard.timehabili')}}
        </form-input>
        <form-input v-else-if="$i18n.locale == 'en'" type="radioGroup" :groupOptions="activationKindsEn" v-model="activation.kind">
          {{$t('activitycard.timehabili')}}
        </form-input>
-->  

        <div v-if="activation.kind === 'FIXED'" class="grid grid-2">

              <div v-if="$i18n.locale == 'es'">
                <div :style="text">
                  {{$t('activitycard.desde')}}
                </div>
                <datepicker :style="calendar" v-model="activation.startDate" inputFormat="dd-MM-yyyy"  :lower-limit="lowerlimit" :locale="dateEs"/>

                <div :style="text">
                  {{$t('activitycard.hasta')}}
                </div>
                <datepicker :style="calendar" v-model="activation.endDate" inputFormat="dd-MM-yyyy"  :lower-limit="activation.startDate" :locale="dateEs"/>
              </div>

              <div v-else-if="$i18n.locale == 'en'">
                <div :style="text">
                  {{$t('activitycard.desde')}}
                </div>
                <datepicker :style="calendar" v-model="activation.startDate" inputFormat="dd-MM-yyyy"  :lower-limit="lowerlimit" :locale="dateEn"/>

                <div :style="text">
                  {{$t('activitycard.hasta')}}
                </div>
                <datepicker :style="calendar" v-model="activation.endDate" inputFormat="dd-MM-yyyy"  :lower-limit="activation.startDate" :locale="dateEn"/>
              </div>


        </div>
      </form>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showActivationModal = false">{{$t('activitycard.cerrar')}}</button>
      <button type="submit" class="btn btn--primary" form="activationform" :disabled="activateButtonDisabled">{{$t('activitycard.activar')}}</button>
    </template>
  </modal>
    <modal v-if="showInformationModal" @close="showInformationModal = false">
    <template #title>{{$t('activitycard.title')}}</template>
    <template v-if="myActivity.is_currently_active" #description>{{$t('activitycard.infodisp')}} <strong>{{activityStartDate}}</strong> {{$t('activitycard.al')}}  <strong>{{activityEndDate}}</strong></template>
    <template v-else-if="myActivity.is_active" #description>{{$t('activitycard.unlimiteddisp')}}</template>
    <template #content>
      <p>{{$t('activitycard.copylink')}}</p>
      <div class="code_lesslink">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg"  @click="copyModal('enlaceactividad')">
          </a>
        </div>
       <pre class="code__content"><code>{{activityLink}}</code></pre>
      </div>
      <p>{{$t('activitycard.copylinksecond')}}</p>
       <div class="code_lesslink">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg"  @click="copyModal('enlacegeneral')">
          </a>
        </div>
        <pre class="code__content"><code>{{generalActivityLink}}</code></pre>
      </div>
      <p>{{$t('activitycard.uniccode')}}</p>
      <div class="code">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg" @click="copyModal('enlacecode')">
          </a>
        </div>
        <pre class="code__content"><code>{{myActivity.activity_event.code}}</code></pre>
      </div>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showInformationModal = false">{{$t('activitycard.cerrar')}}</button>
    </template>
  </modal>
</template>

<script>
let today = new Date()
today = `${today.getFullYear()}-${`${today.getMonth() + 1}`.padStart(2, '0')}-${`${today.getDate()}`.padStart(2, '0')}`

import BaseViewLayout from '@/components/BaseViewLayout'
import DisplayQuestion from '@/components/DisplayQuestion'
import Modal from '@/components/Modal'
import { inject, reactive} from 'vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { useRouter } from 'vue-router'
import FormInput from '@/components/FormInput'
import Datepicker from 'vue3-datepicker'
import { es,en } from 'date-fns/locale'


export default {
  name: 'ActivityShow',
  setup () {
    
    const route = useRouter().currentRoute.value
    const { activityId, code } = route.params

    const axios = inject('$axios')
    const activity = reactive({ questions: [] })
    const answers = reactive([])
    let activityUrl = `activities/${activityId}`
    let questionsUrl = `activities/${activityId}/questions`
    const enlace = ''

    

if (route.meta.isTutor) {
      activityUrl = `answers/activity-by-code/${code}`
      questionsUrl = `answers/questions-by-activity-code/${code}`
    }

    axios.get(activityUrl).then(res => {
      Object.keys(res.data.activity).forEach(key => activity[key] = res.data.activity[key])
    })
    axios.get(questionsUrl).then(res => {
      res.data.questions.forEach(q => {
        answers.push({
          question_id: q.id,
          value: q.kind === 'MULTIPLE-CHOICE-MULTIPLE-OPTION' ? [] : null
        })
      })
      activity.questions = res.data.questions
    })
    return { activity, answers, axios, enlace}
    
  },
  data () {
    return {
      submitting: false,
      showConfirmDeletionModal: false,
      showEndingModal: false,
      showDeactivationModal: false,
      showInformationModal: false,
      localActivity: null,
      aux2:[{}],
      dateEn: en,
      dateEs: es,
      showActivationModal: false,
      lowerlimit:new Date(),
      calendar:{
        height: '50px',
        width: '100%',
        maxWidth: '500px',
        padding: 'var(--space-no) var(--space-n)',
        margin: 'var(--space-no)',
        backgroundColor: 'var(--color-white)',
        border: '1px solid var(--color-tertiary--95)',
        borderRadius: 'var(--radius)',
        lineHeight: '28px',
        MsBoxSizing:' border-box',
        OBoxSizing: 'border-box',
        boxSizing: 'border-box',
        "--vdp-selected-bg-color": "var(--color-primary)",
        "--vdp-hover-bg-color": "var(--color-primary)",
        "--vdp-box-shadow": "0 4px 10px 0 rgba(0, 0, 0, 0.8), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
        "--vdp-text-color": "#000000",
        "--vdp-elem-font-size": "1em",
        },
        text:{
          display:' block',
          fontFamily: "RobotoCondensed-Bold",
          fontSize:' var(--caption-xs)',
          textTransform: 'uppercase',
          lineHeight: 'var(--caption)',
          marginBottom: 'var(--space-xs)',
          color: 'var(--color-text)',
          marginLeft: 'var(--space-xs)',
          textAlign: 'left'
        },
      activationKinds: [
        {
          id: 'INDEFINITE',
          name: 'ILIMITADO',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        },
        {
          id: 'FIXED',
          name: 'RANGO DE FECHA',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activationKindsEn: [
        {
          id: 'INDEFINITE',
          name: 'UNLIMITED',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        },
        {
          id: 'FIXED',
          name: 'DATE RANGE',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activation: {
        kind: null,
        startDate: new Date(),
        endDate: null
      },
      activationDatesLimits: {
        minStartDate: today,
        maxStartDate: null,
        minEndDate: today
      },
      activationKindsIndefinite: [
        {
          id: 'INDEFINITE',
          name: 'ILIMITADO',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activationKindsIndefiniteEn: [
        {
          id: 'INDEFINITE',
          name: 'UNLIMITED',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        },
      ],
      activationKindsRange: [
        {
          id: 'FIXED',
          name: 'RANGO DE FECHA',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activationKindsRangeEn: [
        {
          id: 'FIXED',
          name: 'DATE RANGE',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      disabled:false,
      deactivateOptions:true,
      showModalPdf: false,
      fileIdPdf: '',
      urlPdf:'',

    }
  },
async beforeMount () {
   const route = useRouter().currentRoute.value
    const { activityId, code } = route.params
    let instructionactivityURL = `instruction-activity/instruction-by-id/${activityId}`

if (route.meta.isTutor) {
      instructionactivityURL = `instruction-activity/instruction-by-code/${code}`
      
    }    


    const instruction = (await this.$axios.get(instructionactivityURL)).data.instruction[0].instruction

      if(instruction.includes("slide divider")){
        const aux = instruction.split("---slide divider---")
        for (var i = 0; i < aux.length; i++) {
          this.aux2[i] = new Object();
          this.aux2[i].name = aux[i];
      }
    }
},
  methods: {
    saveAnswers () {
      this.submitting = true
      this.axios.post('answers', {
        name: this.$route.query.name,
        activity_event_id: this.activity.activity_event.id,
        answers: this.answers
      }).then(res => {
        if (res.data.answers) {
          this.showEndingModal = true
        }
      })
      
    },
    displayDeactivationModal () {
      this.showDeactivationModal = true
      this.showInformationModal = false
    },
    cancelDeactivation () {
      this.showDeactivationModal = false
      this.showInformationModal = true
    },
    async deleteActivity () {
      this.submitting = true
      await this.axios.delete(`activities/${this.activity.id}`)
      this.$router.push({ name: 'Activities' })
    },
    async deactivateEvent () {
      this.submitting = true
      await this.$axios.delete(`activations/${this.myActivity.activity_event.id}`)
      this.localActivity = this.$utils.cloneObj(this.activity)
      this.localActivity.activity_event = null
      this.localActivity.is_currently_active = false
      this.showDeactivationModal = this.submitting = false
      this.deactivateOptions = false
    },
    async duplicateActivity () {
      this.submitting = true
  
      const question = (await this.axios.get(`questionActivities/${this.activity.id}`)).data.questionActivities
      await this.axios.post('questionActivities/', {
        activity: this.activity,
        questions: question 
      }).data
      alert(this.$t('activityshow.successmsg'))
    },    
    displayActivationModal () {
      this.activation = {
        kind: null,
        startDate: new Date(),
        endDate: null
      }
      this.showActivationModal = true
    },
    async editActivity () {
      this.submitting = true
      const data = (await this.$axios.patch(`activations/${this.activity.id}`, {
        startDate: this.activation.startDate,
        endDate: this.activation.endDate,
        codeEvent:this.myActivity.activity_event.code,
        eventId:this.myActivity.activity_event.id,
        eventIsUnlimited:this.myActivity.activity_event.is_active,
        eventIsRange:this.myActivity.is_currently_active,
        kind: this.activation.kind,
      })).data
      this.myActivity = this.$utils.cloneObj(this.activity)
      this.myActivity.activity_event = data.activity.activity_event
      this.myActivity.is_currently_active = data.activity.is_currently_active
      this.showActivationModal = this.submitting = false
      this.showInformationModal = true
    },
    copy(enlace){
      
      if(enlace=='enlace1'){
        navigator.clipboard.writeText(this.activity.external_resource_url_print)

      }else if(enlace=='enlace2'){
        const lastSlashIndex = this.activity.external_resource_url_recom.lastIndexOf('/');
        this.urlPdf = this.activity.external_resource_url_recom.substring(0, lastSlashIndex + 1) + "preview";
        const ancho =800;
        const altura = 600;
        const opciones = `width=${ancho},height=${altura},top=${(window.innerHeight - altura) / 2},left=${(window.innerWidth - ancho) / 2},resizable,scrollbars=yes`;
        window.open(`${this.urlPdf}`, '_blank',opciones);
        navigator.clipboard.writeText(this.activity.external_resource_url_recom)
      }else if(enlace=='enlace3'){
        navigator.clipboard.writeText(this.activity.external_resource_url_apod)
      }else if(enlace=='enlace4'){
        navigator.clipboard.writeText(this.activity.guia_docente_interactiva)
 
      }else{
        console.log(this.activityLink)
        navigator.clipboard.writeText(this.activityLink)
      }
      
    },
    copyModal(enlace){
      if(enlace == 'enlaceactividad'){
        navigator.clipboard.writeText(this.activityLink)
      }if(enlace == 'enlacegeneral'){
        navigator.clipboard.writeText(this.generalActivityLink)
      }if(enlace == 'enlacecode'){
        navigator.clipboard.writeText(this.myActivity.activity_event.code)
      }
    },
    typeActivation(){
      if(this.myActivity.activity_event.is_active){
        this.disabled = true
      }else{
        this.disabled = false
      }
    },
    cancelShowPdfModal(){
      if(this.showModalPdf){
        this.showModalPdf = false
      }else{
        this.showModalPdf = true
      }
    },

  },
  components: { BaseViewLayout, DisplayQuestion, Modal,Carousel,Slide,Pagination,Navigation,FormInput,Datepicker},
  computed: {
    myActivity () {
      let myActivity = this.activity || this.localActivity
      myActivity.grade = this.activity.grade
      myActivity.subject = this.activity.subject
      return myActivity
    },
    isCurrentlyOrIndefinitelyActive () {
      return (this.myActivity.activity_event || {}).is_active || this.myActivity.is_currently_active
    },
    activityLink () {
      return `${window.location.origin}${this.$router.getRoutes().find(r => r.name ==='AnswerActivity').path}`.replace(':code',`${this.myActivity.activity_event.code}?name=meaning`)
    },
    generalActivityLink () {
      if (!this.myActivity.activity_event) return false
      return `${window.location.origin}${this.$router.getRoutes().find(r => r.name ==='AccessActivity').path}/`
    },
    activityAuthor () {
      return `${this.myActivity.user.first_name} ${this.myActivity.user.last_name}`
    },
    isTutor () { return this.$store.state.user.id === undefined },
    isEditable () {
      return !this.isTutor && (this.myActivity.user_id === this.$store.state.user.id || this.$store.state.user.role_id === 1)
    },
    isDeletable () {
      return !this.isTutor && (this.myActivity.user_id === this.$store.state.user.id || this.$store.state.user.role_id === 1)
    },
    isDuplicable () {
      return !this.isTutor && (this.myActivity.user_id === this.$store.state.user.id || this.$store.state.user.role_id === 1)
    },
    isActivatable(){
      let activityEvent = this.myActivity.activity_event
      if (activityEvent == null) {
         activityEvent = false
      } else if (activityEvent.end_date < today && activityEvent.end_date != null ) {
        activityEvent = false
      }
      else {
        activityEvent = !this.isTutor && (this.myActivity.activity_event.user_id === this.$store.state.user.id || this.$store.state.user.role_id === 1)
      }
      return activityEvent
    },
    showMaterial (){
      return this.myActivity.external_resource_url_apod != null || this.myActivity.external_resource_url_recom != null || this.myActivity.external_resource_url_print != null || this.myActivity.guia_docente_interactiva != null
    },
    expandedTutorSideNav: {
      get () {
        return Object.values(this.$store.state.user.ui_data)[0]
      },
      set (val) {
        this.$store.commit('setTutorSideNavExpanded',this.isTutor && val)
      }
    },
    activityStartDate () {
      if(this.$i18n.locale == 'en') return new Date(`${this.myActivity.activity_event.start_date}T00:00:00`).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' } )
      return new Date(`${this.myActivity.activity_event.start_date}T00:00:00`).toLocaleDateString('es-CL', { day: 'numeric', month: 'long', year: 'numeric' } )
      },
    activityEndDate () {
      if(this.$i18n.locale == 'en') return new Date(`${this.myActivity.activity_event.end_date}T00:00:00`).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' } )
      return new Date(`${this.myActivity.activity_event.end_date}T00:00:00`).toLocaleDateString('es-CL', { day: 'numeric', month: 'long', year: 'numeric' } )
    },
    activateButtonDisabled () {
      /* The 'Activar' button on the activation modal should be disabled if:
      No kind has been selected OR (
        the selected kind is 'FIXED' AND (
          either startDate or endDate are missing
          OR startDate > endDate
          OR startDate < minStartDate
          OR startDate > maxStartDatel
          OR endDate < minEndDate
        )
      ) */

      
      return !this.activation.kind || (
        this.activation.kind === 'FIXED' && (
          !(this.activation.startDate && this.activation.endDate)
          || this.activation.startDate > this.activation.endDate
          || this.activation.startDate < this.activationDatesLimits.minStartDate
          || this.activation.startDate > this.activationDatesLimits.maxStartDate
          || this.activation.endDate < this.activationDatesLimits.minEndDate
        )
      )
    },
 
  },
  watch: {
    'activation.startDate' () {
      this.activationDatesLimits.minEndDate = this.activation.startDate ? this.activation.startDate : today
    },
    'activation.endDate' () {
      this.activationDatesLimits.maxStartDate = this.activation.endDate // ? this.activation.endDate : today
    },
  },
}
</script>

<style>
.resource__icon{
   cursor:pointer !important;
}

.slide{
  background-color: var(--color-white);
  border-radius: var(--radius);
  width: 50%;
}

.main-activity-with-carrousel{
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.content-with-carrousel{
  display: flex;
  flex-direction: column;
   
}

.carrousel{
  margin-bottom: var(--space-l);
  display: flex;
  justify-content: center;
  }


.slide-content{
  padding: 2rem;
  width: 100%;
}

.side-info-with-carrousel{
  display: flex;
  justify-content: space-around;
  margin-bottom: var(--space-l);
}

.about-activity{
  width: 50%;
}

.carousel{
  text-align: justify !important;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


@media (max-width: 428px){
  .layout .content-wrapper .main-content {
    min-height: calc(100vh - var(--space-m) * 2);
    padding: var(--space-s) var(--space-m);
  }
  .titles-header{
    margin-left: -1rem;
  }
  .breadcrumb{
    margin-left: -1rem;
  }
  .main-activity-with-carrousel{
    padding: 0rem;
  }

.carousel__prev, .carousel__next {
    background-color: var(--color-primary) !important;
    top: 95% !important;
    }

  .side-info-with-carrousel {
    flex-direction: column;
  }
  .about-activity{
    width: 100%;
     margin-bottom: var(--space-l);
  }
  .carousel__slide--prev{
    opacity: 0 !important;
  }
  .carousel__slide--next{
    opacity: 0 !important;
  }

  .carousel__slide--visible{
    padding: 1rem;
   
  }

  .carousel__prev {
    margin-inline-start: 1.5rem;
  }

  .carousel__next {
    margin-inline-end:  1.5rem;

}
.carousel__pagination-button{
    margin: 0.5px;

}

 .carousel__pagination{
  padding-inline-start: 0px;
}

figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 1em;
}

.carousel{
  text-align: justify !important;
}

.slide{
  width: 100%;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.code_lesslink{
  display: inline-flex;
  align-items: center;
  background-color: var(--color-tertiary--95);
  border-radius: var(--radius);
  margin-bottom: var(--space-n);
  width: 90%;
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
}

.activity-grid{
  grid-template-columns: 1fr;
  grid-template-areas:
    "a"
    "b";
}

}

@media (max-width: 375px){
  .titles-header{
    margin-left: -1rem;
  }
  .breadcrumb{
    margin-left: -1rem;
  }
  .main-activity-with-carrousel{
    padding: 0rem;
  }
  .side-info-with-carrousel {
    flex-direction: column;
  }
  .about-activity{
    width: 100%;
     margin-bottom: var(--space-l);
  }
  .carousel__prev {
    margin-inline-start: 1.5rem;
  }

  .carousel__next {
    margin-inline-end:  1.5rem;

}
  .carousel__prev, .carousel__next {
    background-color: var(--color-primary) !important;
    top: 95% !important;
    }

    .carousel__pagination-button{
    margin: 0.5px;

}
.carousel{
  text-align: justify !important;
}

.slide{
  width: 100%;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.activity-grid{
  grid-template-columns: 1fr;
  grid-template-areas:
    "b"
    "a";
}


}

@media (max-width: 1280px) {
  .side-info-with-carrousel{
  justify-content: space-between;
}
  
}
  /*.activity-side--content{
  background-color: greenyellow;
}
  .main-section{
    background-color: blue;
    
  }
  .slide{
    background-color: red;
  }
*/
.carousel__prev, .carousel__next {
    background-color: var(--color-primary) !important;
    top: 100%;
}
.carousel__pagination-button--active {
    background-color: var(--color-primary)!important;
}

.no-side-info{
  justify-content: center;
}

.resource__icon_resize{
  cursor: pointer;
  width: 3%;
}

.code_lesslink{
display: inline-flex;
  align-items: center;
  background-color: var(--color-tertiary--95);
  border-radius: var(--radius);
  padding: var(--space-s) var(--space-s);
  width: auto;
  margin-bottom: var(--space-n);
}

.code_lesslink .code__content {
    margin: var(--space-no);
    padding: var(--space-no) var(--space-s);
}

.modal-backdrop .modal--large {
    width: 100%;
    max-width: 830px;
}

.radiogroup{
  display:flex;
  flex-direction: row;
  text-align: left;
}
.enabletime{
  display: flex;
  flex-direction: column;
  font-family: "RobotoCondensed-Bold";
  font-size: var(--caption-xs);
  text-transform: uppercase;
  line-height: var(--caption);
  color: var(--color-text);
  max-width: 500px;
  text-align: center;
  align-items: flex-start;
}

.container-radiogroup{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.checkbox__group{
  margin-right: var(--space-m);
  
}

.indefinite-inactive {
  pointer-events:none;
  width: 100%;
  opacity: 0.5;
}

.indefinite-active{
  pointer-events:auto;
   width: 100%;
}

.range-indefinite{
   width: 100%;
}

.range-indefinite .checkbox__group .checkbox__label{
  border: 2px solid var(--color-text--alt);
}

.range{
   width: 100%;

}
.link-style {
  cursor: pointer;
  }


</style>
